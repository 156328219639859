/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import type { RootState } from '../store';
import { fetchApi } from '../helpers/fetchApi';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardHeader,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import ZoomOutSharpIcon from '@mui/icons-material/ZoomOutSharp';
import ZoomInSharpIcon from '@mui/icons-material/ZoomInSharp';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
  createStyles({
    stickyHeader: {
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
    },
  }),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '2px 10px'
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '2px 10px'
}

const estiloAzul = {
  color: "white",
  backgroundColor: "blue",
  padding: '5px 10px',
}

const estiloAmarillo = {
  color: "white",
  backgroundColor: "#FFA600",
  padding: '2px 10px'
}

export default function PermisoIngresoTratadosComponente(props) {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const { refresco } = useSelector((state: RootState) => state.refrescar);
  const navigate = useNavigate();
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(true);
  const [permisos, setPermisos] = useState<any>([]);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const lengthPrevia = useRef(0);
  const urlGetPersonalPermisoTratado = `https://scaapi.tecpetrol.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetPersonalPermisoTratado?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}`;
  const getPersonalPermisoTratado = async() => {
    return await fetchApi(`${urlGetPersonalPermisoTratado}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };

  const [orderColumn, setOrderColumn]= useState("Fecha");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(permisos, columnName, columnOrder);

    setPermisos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async () => {
    const [payload, error] = await getPersonalPermisoTratado();
    if(!error){
      lengthPrevia.current = payload.length
      setCargando(true);
      setPermisos(payload);
      setCargando(false);
      setUltimoRefresco(new Date().toTimeString());
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    const [payload, error] = await getPersonalPermisoTratado();
    if(!error){
      if(payload.length !== lengthPrevia.current){
        leer();
      }
    }
  }
  
  useEffect(() => {
    leer();
  }, [obra])

  useEffect(() => {
    if(props.isVisible){
      if(!cargando)
      verificarNuevosDatos();
    }else{
      leer();
    }
  }, [refresco])

  return (
    <Card sx={{ minWidth: 100 }} elevation={5} id="6">
      <CardHeader
        action={
          <ButtonGroup variant="text" aria-label="outlined primary button group">
            <Button size="small" disabled={cargando} color='info' variant='text' onClick={() => leer()}><CachedIcon /></Button>
            <Button size="small" onClick={() => props.setColumnasFull()}><ZoomInSharpIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasHalf()}><ZoomOutSharpIcon/></Button>
            <Button size="small" onClick={() => navigate('/permisoDeIngresoTratados')}><FullscreenIcon/></Button>
            <Button size='small' onClick={() => props.handleHide()}><CloseIcon/></Button>
          </ButtonGroup>
        }
        title="Permisos Ingreso TRATADOS"
      >
      </CardHeader>
      <CardContent>
        <FormControl fullWidth>
          <InputLabel>Obra</InputLabel>
          <Select
            label="Obra"
            value={obra.Id_Zone.toString()}
            fullWidth
            size="small"
            disabled
          >
            <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
          </Select>
        </FormControl>
        <Button>Buscar</Button>
        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 550 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Tipo_Autorizacion')}>
                  <TableSortLabel active={orderColumn == 'Tipo_Autorizacion'} direction={orderColumn != 'Tipo_Autorizacion' || orderDirection === "asc"? "asc" : "desc"}>
                  Tipo
                    </TableSortLabel>                    
                    </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                    <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                      Nombre
                    </TableSortLabel>
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Dni')} align="right">
                  <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                  Dni
                    </TableSortLabel>                    
                    </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                  <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                  Contratista
                    </TableSortLabel>                    
                    </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Supervisor')}>
                  <TableSortLabel active={orderColumn == 'Supervisor'} direction={orderColumn != 'Supervisor' || orderDirection === "asc"? "asc" : "desc"}>
                  Aprobador
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Requisito_Faltante')}>
                    <TableSortLabel active={orderColumn == 'Requisito_Faltante'} direction={orderColumn != 'Requisito_Faltante' || orderDirection === "asc"? "asc" : "desc"}>
                      Requisito Faltante
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                      Fecha
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell align="right">
                    Estado
                    </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (permisos.map((permiso: any, index) => (
                <TableRow
                  key={index}
                  id={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  // selected={index === rowSelectIndex ? true : false}
                  onClick={() => {
                    setRowSelectIndex(index);
                  }}
                >
                  <TableCell style={{ padding: '2px 10px' }} component="th" scope="row">
                    {permiso.Tipo_Autorizacion}
                  </TableCell>
                  <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Nombre}</TableCell>
                  <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Dni}</TableCell>

                  {permiso.Contratista === "- Pendiente de Carga" 
                  ? <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2}}>{permiso.Contratista}</p></TableCell>
                  : <TableCell style={{ padding: '2px 10px' }} align="center">{permiso.Contratista}</TableCell>
                  }

                  <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Supervisor}</TableCell>
                  <TableCell style={{ padding: '2px 10px' }} align="right">
                    <Box style={{ maxWidth: '15vw' }}>
                      <Tooltip title={permiso.Requisito_Faltante}>
                        <Typography noWrap align="left">{permiso.Requisito_Faltante}</Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Fecha}</TableCell>
                  {permiso.Estado !== "APROBADO"  // APROBADO (verde) - RECHAZADO (rojo) - NO CORRESPONDE (azul)
                    ? (permiso.Estado === "RECHAZADO") 
                    ? <TableCell align="center" style={estiloRojo}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                    : <TableCell align="center" style={estiloAzul}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                    : <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                  }
                </TableRow>
              )))}
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}