/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import type { RootState } from '../store';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardHeader, 
  Checkbox,
  Divider,
  Grid,
  Button,
  ButtonGroup,
  Paper,
  Box,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { fetchAutoridades, fetchContratistas, fetchGetDriver, fetchPermisosDni, fetchGetPersonalPermisoTratadoDni, fetchSetVisitaOk, fetchSetEntryPeatonal, fetchGetMotivosAutorizacion, fetchSetEntryAuthorizationAuthorized, fetchSetEntryPeatonalAuditor, fetchSetEntryAuthorizationPeatonal } from '../reducers/fetchWebService';
import ZoomOutSharpIcon from '@mui/icons-material/ZoomOutSharp';
import ZoomInSharpIcon from '@mui/icons-material/ZoomInSharp';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import { useCookies } from "react-cookie";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    boxHeight: {
      height: '1em',
    },
    gridContainer: {
      marginBottom: 5,
    },
    estado: {
      padding: '0.7rem',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.38)',
      borderRadius: 4,
    }
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

enum Estados {
  LIMPIO = '',
  APTO = 'APTO',
  NO_APTO = 'NO APTO',
  NO_APTO_PERMISO = 'NO APTO CON PERMISO',
  NO_CARGADO = 'NO CARGADO',
}

enum Tipos {
  PERSONAL = 'PERSONAL',
  INVITADO = 'INVITADO',
  PENDIENTE = 'PENDIENTE DE CARGA',
  VISITA = 'VISITA CON VEHICULO PROPIO',
}

enum TiposDeEntrada {
  INTERNA = 'I',
  EXTERNA = 'E',
}

const estadoInicialForm = {
  dni: 'escan',
  IdDriver: 0,
  dniIngresado: '',
  nombre: '',
  estado: Estados.LIMPIO,
  permisosSegunDni: [{Dni: 0, Cantidad: 0}],
  tipo: '',
  internoExterno: 'E',
  autorizarPermiso: false,
  contratistas: [{Id_Customer: 0, Name: "", Id_Work_Status: 0, VersionNumber: 0}],
  autoridades: [{Id_Supervisor: 0, Name_Supervisor: ""}],
  tipoVisita: 'gen',
  contratistaSeleccionado: '1661',
  contratistaNameSeleccionado: {Id_Customer: 1661, Name: '- Otra (Pendiente de alta)'}, // TODO: VER
  aprobadorFiltro: '',
  autoridadesSeleccionadas: [],
  notaAprobador: '',
  motivos: [{Id:1, Descripcion: "Otros"},{Id: 3,Descripcion: "Permitido"}],
  notaMotivo:'',
  idMotivo: 1,
  descMotivo: 'Otros'
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '1rem',
  overflow: 'auto',
};

export default function IngresoPeatonal(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { obra, contratistas } = useSelector((state: RootState) => state.selectorObra);
  const [autoridadesLocal, setAutoridadesLocal] = useState<any>(estadoInicialForm.autoridades);
  const [autoridades, setAutoridades] = useState<any>(autoridadesLocal);
  const [listadoPermisosDni, setListadoPermisosDni] = useState<any>(estadoInicialForm.permisosSegunDni);
  const fecha = new Date().toLocaleDateString();

  const [dni, setDni] = useState(estadoInicialForm.dni); //escaneo o manual != DNI Ingresado
  const [idDriver, setIdDriver] = useState(estadoInicialForm.IdDriver);
  const [nombre, setNombre] = useState(estadoInicialForm.nombre);
  const [estado, setEstado] = useState(estadoInicialForm.estado);
  const [dniIngresado, setDniIngresado] = useState(props.dniPersonaDetalleVehiculo);
  const [permisosSegunDni,setPermisosSegunDni] = useState(estadoInicialForm.permisosSegunDni);
  const [tipo, setTipo] = useState(estadoInicialForm.tipo);
  const [internoExterno, setInternoExterno] = useState(estadoInicialForm.internoExterno);
  const [aprobadorFiltro, setAprobadorFiltro] = useState(estadoInicialForm.aprobadorFiltro);
  const [autorizarPermiso, setAutorizarPermiso] = useState(estadoInicialForm.autorizarPermiso);
  const [tipoVisita, setTipoVisita] = useState(estadoInicialForm.tipoVisita);
  const [contratistaSeleccionado, setContratistaSeleccionado] = useState(estadoInicialForm.contratistaSeleccionado);
  const [contratistaNameSeleccionado, setContratistaNameSeleccionado] = useState(estadoInicialForm.contratistaNameSeleccionado);
  const [autoridadesSeleccionadas, setAutoridadesSeleccionadas] = useState(estadoInicialForm.autoridadesSeleccionadas);
  const [notaAprobador, setNotaAprobador] = useState(estadoInicialForm.notaAprobador);
  const [cookies] = useCookies();
  const [esModal, setEsModal] = useState(false);

  const [motivos, setMotivos] = useState(estadoInicialForm.motivos);
  const [idMotivo, setIdMotivo] = useState(estadoInicialForm.idMotivo);
  const [descMotivo, setDescMotivo] = useState(estadoInicialForm.descMotivo)
  const [notaMotivo, setNotaMotivo] = useState(estadoInicialForm.notaMotivo);
  const [openModalMotivo, setOpenModalMotivo] = useState(false);

  const handleOpenModalMotivo = () => {
    setOpenModalMotivo(true);
  } 
  const handleCloseModalMotivo = () => setOpenModalMotivo(false);

  const traerMotivosAutorizacion = async () => {
    let respMotivos: any;
    respMotivos  = await fetchGetMotivosAutorizacion();
    setMotivos(respMotivos);
  }

  const datosFetch = {
    fechaMovimiento: fecha + ' ' + new Date().toTimeString().slice(0, 8),
    idPersonal: idDriver,
    dniPersonal: dniIngresado,
    nombrePersonal: nombre,
    tipoMovimiento: 'I',
    TipoPeatonal: 1, // ??
    idCustomer: contratistaSeleccionado,
    idJefeSupervisor: '0',
    idJefesSupervisor: '0',
    obsPeatonal: notaAprobador,
    accesoNombre: obra.Name,
    latPosicion: obra.Lat,
    lonPosicion: obra.Lon,
    idUser:cookies.Id_User, 
    tipoEntrada: internoExterno,
    idMotivo: idMotivo,
    descripcionMotivo: descMotivo,
    obsMotivo: notaMotivo
  }

  const setDatosPersonal = async (dni?, nombreCompleto?) => {
    let dniLocal = dni? dni : dniIngresado;
    let resp =  await fetchGetDriver(dniLocal);
    if(resp.Id_Driver !== 0){
      setIdDriver(resp.Id_Driver);
      setNombre(resp.Name + ' ' + resp.Last_Name);
      if (resp.Visita)
        setTipo(Tipos.VISITA);
      else
        setTipo(Tipos.PERSONAL);
      setNotaAprobador(resp.Visita_Obs);
      setContratistaSeleccionado(resp.Id_Customer)
      setContratistaNameSeleccionado({Id_Customer: resp.Id_Customer, Name: resp.Customer_Name})
      if(resp.Vigencia !== 2){
        setEstado(Estados.APTO);
      }else{
        setEstado(Estados.NO_APTO);
        let respGetPersonalTratadoDni = [];
        const [payload, error] = await fetchGetPersonalPermisoTratadoDni(dniLocal);
        if(!error){
          respGetPersonalTratadoDni = payload as any[];
        }
        if(respGetPersonalTratadoDni.length > 0){
          respGetPersonalTratadoDni.forEach(permiso => {
            if(permiso.Estado === 'APROBADO'){
              setEstado(Estados.NO_APTO_PERMISO);
              return;
            }
          });
        }
      }
    }else{
      setIdDriver(estadoInicialForm.IdDriver);
      setNombre(dni == 'escan' ? nombreCompleto : estadoInicialForm.nombre);
      setContratistaSeleccionado(estadoInicialForm.contratistaSeleccionado)
      setContratistaNameSeleccionado(estadoInicialForm.contratistaNameSeleccionado)
      setEstado(Estados.NO_CARGADO);
      setTipo(Tipos.PENDIENTE);
    }
  }

  const setPermisosUltimosDias = (dni) => {
    setPermisosSegunDni(estadoInicialForm.permisosSegunDni);
    let permisosPersona = listadoPermisosDni.filter((persona) => persona.Dni == dni);
    if(permisosPersona.length > 0){
      setPermisosSegunDni(permisosPersona);
    }
  }

  const leerDniEscaneado = (stringEscaner) => {
    let textoIngresado = stringEscaner;
    let chr_div = '';
    let nombre = '';
    let apellido = '';
    let dni = '';
    // let textoIngresado = `"28230270    "A"1"LEGUIZA"SANTIAGO ROBERTO"ARGENTINA"06-06-1980"M"07-02-2012"00093505359"7000 "07-02-2027"7"0"ILRÑ2.20 CÑ110927.01 )GM-EXE'MOVE'HM="UNIDAD #16 ]] S-NÑ 0040:2008::0021`
    // let textoIngresado = `00218959143@CANOVA@FACUNDO@M@25477038@A@09-08-1976@18-09-2013`

    if(textoIngresado.search("\"") !== -1){
      chr_div = "\"";
    }else{
      chr_div = "@";
    }

    let data = textoIngresado.split(chr_div);

    if(chr_div == '@'){
      dni = data[4];
      nombre = data[2];
      apellido = data[1];
    }else{
      dni = data[1];
      nombre = data[5];
      apellido = data[4];
    }

    let nombreCompleto = nombre + ' ' + apellido;

    setAutoridadesSeleccionadas([]);
    setAutoridades([]);

    traerDatos();

    setTimeout(
      () => setDniIngresado(dni), 
      1000);

    setPermisosUltimosDias(dni)
    
    setTimeout(
      () => setDatosPersonal(dni, nombreCompleto), 
      1000);
  }

  const handleChangeAutocompletar = async (value) => {
    setContratistaNameSeleccionado(value);
    
    setContratistaSeleccionado(value.Id_Customer);
  }

  const handleChange = async (event) => {
    if(event.target.name === 'dni'){
      setDni(event.target.value);
    }
    if(event.target.name === 'dniIngresado'){
      if(event.key === 'Enter' || event.key === 13){
        event.preventDefault();
        if(dni !== 'escan'){
          handlePermisosDniManual();
        }else{
          leerDniEscaneado(event.target.value);
        }
      }
      if(dni !== 'escan'){
        setDniIngresado(event.target.value);
      }
    }
    if(event.target.name === 'nombre'){
      setNombre(event.target.value);
    }
    if(event.target.name === 'tipoVisita'){
      setTipoVisita(event.target.value);
      if(event.target.value == 'aut'){
        setAutorizarPermiso(false);
      }
    }
    if(event.target.name === 'internoExterno'){
      setInternoExterno(event.target.value);
    }
    if(event.target.name === 'tipo'){
      setTipo(event.target.value);
    }
    if(event.target.name === 'aprobadorFiltro'){
      let filtro = event.target.value.toLowerCase();

      if(filtro.length < aprobadorFiltro.length){
        setAprobadorFiltro(filtro);
        
        let listadoAutoridadesFiltradas = autoridadesLocal.filter((autoridad) => autoridad.Name_Supervisor.toLowerCase().includes(filtro))
        
        setAutoridades(listadoAutoridadesFiltradas.sort(function (a,b) {return a.Name_Supervisor.localeCompare(b.Name_Supervisor)}))
      }
      else
      {
        setAprobadorFiltro(filtro);

        setAutoridades(autoridades.filter((autoridad) => autoridad.Name_Supervisor.toLowerCase().includes(filtro)))
      }
    }
    if(event.target.name === 'contratista'){
      setContratistaSeleccionado(event.target.value);
    }
    if(event.target.name === 'autoridad'){
      let idAutoridad = event.target.value;
      if(!autoridadesSeleccionadas.includes(idAutoridad)){
        autoridadesSeleccionadas.push(idAutoridad);
        setAutoridadesSeleccionadas(autoridadesSeleccionadas);
        setAutoridades(autoridades.map((auto) => (auto)));
      }else{
        setAutoridadesSeleccionadas(autoridadesSeleccionadas.filter(autoridades => autoridades !== idAutoridad));
        setAutoridades(autoridades.map((auto) => (auto)));
      }
    }
    if(event.target.name === 'notaAprobador'){
      setNotaAprobador(event.target.value);
    }
    if(event.target.name === 'notaMotivo'){
      setNotaMotivo(event.target.value);
    }
  };

  const handlePermisosDniManual = () => {
    setAutoridadesSeleccionadas([]);
    setAutoridades([]);

    traerDatos();

    //aca entra si ingresa DNI manual y apreta el botón
    setPermisosUltimosDias(dniIngresado)
    
    setTimeout(
      () => setDatosPersonal(), 
      1000);
  }

  const handleEnviarACadaAutoridad = async (autoridad) => {
    await fetchSetEntryAuthorizationPeatonal(datosFetch, autoridad);
  }

    //Funciones de alert de alert snackbar
    const [mensajeSnackBar, setMensajeSnackBar] = useState<any>();
    const [tipoSnackBar, setTipoSnackBar] = useState<any>();
    const [openSnackBarAlert, setOpenSnackBarAlert] = useState(false);
  
    const handleOpenSnackBarAlert = () => {
      setOpenSnackBarAlert(true);
    };
  
    const handleCloseSnackBarAlert = () => {
      setOpenSnackBarAlert(false);
    }

    const handeSubmit = async (e) => {
      e.preventDefault();
      let error = [];
  
      if(estado == Estados.APTO || estado == Estados.NO_APTO_PERMISO){
        await fetchSetEntryPeatonal(datosFetch);
        
        setMensajeSnackBar('Se ha grabado el Ingreso');
        setTipoSnackBar('success');
        
        handleOpenSnackBarAlert();

        limpiarFormulario();
      }
  
      if(estado == Estados.NO_APTO && tipo == Tipos.VISITA){
        await fetchSetVisitaOk(datosFetch);
        
        setMensajeSnackBar('Se ha aprobado la Visita');
        setTipoSnackBar('success');
        
        handleOpenSnackBarAlert();

        limpiarFormulario();
      }
  
      if(estado == Estados.NO_APTO && tipo != Tipos.VISITA){
        error = [];
        if(autoridadesSeleccionadas.length == 0){
          error.push('Seleccionar al menos un supervisor')
        }
  
        if(error.length > 0){
          setMensajeSnackBar(error[0]);
          setTipoSnackBar('error');
          handleOpenSnackBarAlert();
        }else{
          autoridadesSeleccionadas.forEach(autoridad => {
            handleEnviarACadaAutoridad(autoridad)
          })
          
          setMensajeSnackBar('Se ha enviado el Permiso');
          setTipoSnackBar('success');
  
          handleOpenSnackBarAlert();

          limpiarFormulario();
        }
      }
  
      if(estado === Estados.NO_CARGADO && tipo != Tipos.VISITA){
        error = [];
        if(nombre == ''){
          error.push('Ingresar Nombre completo')
        }
        if(autoridadesSeleccionadas.length == 0){
          error.push('Seleccionar al menos un supervisor')
        }
        if(error.length > 0){
          setMensajeSnackBar(error[0]);
          setTipoSnackBar('error');
          
          handleOpenSnackBarAlert();
        }else if(tipoVisita == 'aut'){
          datosFetch.idJefesSupervisor = autoridadesSeleccionadas.toString();
          
          await fetchSetEntryPeatonalAuditor(datosFetch);
          
          setMensajeSnackBar('Permiso Enviado');
          setTipoSnackBar('success');
          
          handleOpenSnackBarAlert();

          limpiarFormulario();
        }else{
          autoridadesSeleccionadas.forEach(autoridad => {
            handleEnviarACadaAutoridad(autoridad)
          })
          
          setMensajeSnackBar('Se ha enviado el Permiso');
          setTipoSnackBar('success');
          
          handleOpenSnackBarAlert();

          limpiarFormulario();
        }
      }
    }

  const handleSubmitSetEntryAuthorizationAuthorized = async () => {
    await fetchSetEntryAuthorizationAuthorized(datosFetch);
    
    setMensajeSnackBar('Permiso Enviado');
    setTipoSnackBar('success');
    handleOpenSnackBarAlert();
  };

  const traerDatos = async () => {
    let listadoAutoridades = await fetchAutoridades(obra.Id_Garmin_Group);
    let listadoAutoridadesOrdenadas = listadoAutoridades.sort(function (a,b) {return a.Name_Supervisor.localeCompare(b.Name_Supervisor)});
    
    setContratistaSeleccionado(estadoInicialForm.contratistaSeleccionado)
    setContratistaNameSeleccionado(estadoInicialForm.contratistaNameSeleccionado)
    setAutoridadesLocal(listadoAutoridadesOrdenadas);
    setAutoridades(listadoAutoridadesOrdenadas);
    
    traerMotivosAutorizacion();
  };

  const traerDatosUnaVez = async () => {
    let listadoPermisos = await fetchPermisosDni();
    setListadoPermisosDni(listadoPermisos);
  }

  useEffect(() => {
    traerDatos()
  }, [obra])

  useEffect(() => {
    traerDatosUnaVez();
    if(props.dniPersonaDetalleVehiculo){
      setEsModal(true);

      handlePermisosDniManual();

      setAutoridadesSeleccionadas([]);
      setAutoridades([]);
    } 
  }, [])

  const limpiarFormulario = () => {
    setDni(estadoInicialForm.dni);
    setNombre(estadoInicialForm.nombre);
    setEstado(estadoInicialForm.estado);
    setDniIngresado(estadoInicialForm.dniIngresado);
    setPermisosSegunDni(estadoInicialForm.permisosSegunDni);
    setTipo(estadoInicialForm.tipo);
    setAprobadorFiltro(estadoInicialForm.aprobadorFiltro);
    setInternoExterno(estadoInicialForm.internoExterno);
    setAutorizarPermiso(estadoInicialForm.autorizarPermiso);
    setTipoVisita(estadoInicialForm.tipoVisita);
    setContratistaSeleccionado(estadoInicialForm.contratistaSeleccionado)
    setContratistaNameSeleccionado(estadoInicialForm.contratistaNameSeleccionado)
    setAutoridadesSeleccionadas(estadoInicialForm.autoridadesSeleccionadas);
    setNotaAprobador(estadoInicialForm.notaAprobador);

    setAutoridadesSeleccionadas([]);
    setAutoridades([]);

    traerDatos();
  }

  return (
    <Card sx={{ minWidth: 100 }} elevation={5} id="1">
      <CardHeader
        action={
          <ButtonGroup variant="text" aria-label="outlined primary button group">
            {/* <Button size="small" onClick={() => traerDatos()}><CachedIcon/></Button> */}
            <Button size="small" onClick={() => props.setColumnasFull()}><ZoomInSharpIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasHalf()}><ZoomOutSharpIcon/></Button>
            <Button size="small" onClick={() => navigate('/ingresoPeatonal')}><FullscreenIcon/></Button>
            <Button size='small' onClick={() => !esModal ? props.handleHide() : props.closeModal()}><CloseIcon/></Button>
          </ButtonGroup>
        }
        title="Ingreso Peatonal"
      >
      </CardHeader>
      <CardContent>
      <Snackbar
        open={openSnackBarAlert}
        autoHideDuration={9000}
        onClose={handleCloseSnackBarAlert} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
      <Alert onClose={handleCloseSnackBarAlert} severity={tipoSnackBar} sx={{ width: '100%' }}>
          {mensajeSnackBar}
        </Alert>
      </Snackbar>
        <form 
        action="" 
        style={{ minWidth: '80%' }} 
        onSubmit={(e) => handeSubmit(e)}>
          <Box>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Obra
                </Typography>
              </Grid>
              <Grid item xs={5}>
                  <Select
                    value={obra.Id_Zone.toString()}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    disabled
                  >
                    <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
                  </Select>
              </Grid>
              <Grid item xs={5}>
                <RadioGroup
                  row
                  name="dni"
                  style={{ flexWrap: 'nowrap' }}
                  onChange={handleChange}
                >
                  <FormControlLabel value="escan" control={<Radio size="small" checked={dni === "escan"} style={{ padding: 2 }} />} label={<Typography style={{ fontSize: '0.8rem' }}>Escaneo DNI</Typography>}/>
                  <FormControlLabel value="manual" control={<Radio size="small" checked={dni === "manual"} style={{ padding: 2 }} />} label={<Typography style={{ fontSize: '0.8rem' }}>Ingreso Manual</Typography>}/>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  {dni === 'escan' ? "Escanee el DNI":"Ingrese el DNI"}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {
                  dni === 'escan' 
                  ? (<TextField autoFocus onChange={(e) => handleChange(e)} onKeyDown={(e) => handleChange(e)} fullWidth name='dniIngresado' variant="outlined" size="small"/>)
                  : (
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      <TextField onChange={(e) => handleChange(e)} onKeyDown={(e) => handleChange(e)} name='dniIngresado' variant="outlined" size="small" inputProps={{ maxLength: 8 }} />
                      {/* otra opción es pasar la Prop como Value en el TextField de arriba value={props.dniPersonaDetalleVehiculo} */}
                      <Button onClick={handlePermisosDniManual} variant="contained" size="small"><ManageSearchIcon /></Button>
                    </Stack>
                  )
                }
              </Grid>
            </Grid>
          </Box>

          <Paper variant="outlined" style={{padding: 10}}>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  DNI
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField value={dniIngresado} disabled fullWidth style={{backgroundColor: 'rgba(224,224,224,1)'}} variant="outlined" size="small"/> 
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Nombre y apellido
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField fullWidth 
                disabled={estado == Estados.NO_CARGADO ? false : true} 
                onChange={handleChange} 
                value={nombre} 
                name='nombre' 
                style={estado == Estados.NO_CARGADO ? {} : {backgroundColor: 'rgba(224,224,224,1)'}} 
                variant='outlined'
                size="small"/>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Estado
                </Typography>
              </Grid>
              <Grid item xs={5}>
              <Box 
                className={classes.estado}
                style={estado === Estados.APTO || estado === Estados.NO_APTO_PERMISO ? {backgroundColor: 'green'} : (estado === Estados.NO_APTO || estado === Estados.NO_CARGADO ? {backgroundColor: 'red'} : {minHeight: '2.5rem'})}>
                  <Typography color='white'>{estado}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  direction="row"
                  justifyContent='flex-end'
                  alignItems='center'
                  spacing={2}
                >
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Permisos últimos 30 días
                </Typography>
                <TextField disabled value={permisosSegunDni[0].Cantidad} variant="outlined" style={{ maxWidth: 50, textAlignLast: "center", backgroundColor: 'rgba(224,224,224,1)'}} size="small"/> 
                </Stack>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Tipo
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField disabled fullWidth onChange={handleChange} value={tipo} style={{backgroundColor: 'rgba(224,224,224,1)'}} name="tipo" variant="outlined" size="small"/>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Contratista
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {/* <Select
                  value={contratistaSeleccionado}
                  fullWidth
                  onChange={handleChange}
                  size="small"
                  name='contratista'
                  MenuProps={MenuProps}
                  disabled={estado == Estados.NO_APTO || estado == Estados.NO_CARGADO ? false : true}
                >
                  <MenuItem value={'0'} key={0}>Invitado</MenuItem>
                  {
                    contratistas.map((contratista, index) => (
                      <MenuItem value={contratista.Id_Customer} key={index}>{contratista.Name}</MenuItem>
                    ))
                  }
                </Select> */}
                <Autocomplete
                  disablePortal
                  value={contratistaNameSeleccionado}
                  disabled={(estado == Estados.NO_APTO || estado == Estados.NO_CARGADO) && tipo != Tipos.VISITA ? false : true}
                  onChange={(event, value) => handleChangeAutocompletar(value)}
                  options={contratistas}
                  getOptionLabel={(option : any) => option.Name == undefined ? "" : option.Name}
                  isOptionEqualToValue={(option : any, value : any) => option.Name === value.Name}
                  size="small"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>

            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Tipo de visita
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={tipoVisita}
                  name='tipoVisita'
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  disabled={estado == Estados.NO_CARGADO ? false : true}
                >
                  <MenuItem value="gen">General</MenuItem>
                  <MenuItem value="aut">Autoridad de control</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4} style={estado == Estados.NO_APTO_PERMISO ? { } : { display:'none' }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="internoExterno"
                  defaultValue={internoExterno}
                  onChange={handleChange}
                >
                  <FormControlLabel value={TiposDeEntrada.INTERNA} control={<Radio size="small" style={{ padding: 2 }} />} label="Interno" />
                  <FormControlLabel value={TiposDeEntrada.EXTERNA} control={<Radio size="small" style={{ padding: 2 }} />} label="Externo" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  {tipoVisita === 'gen' ? "Aprobador" : "Jefe Supervisor"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField fullWidth disabled={(estado == Estados.NO_APTO || estado == Estados.NO_CARGADO) && tipo != Tipos.VISITA ? autorizarPermiso : true} name='aprobadorFiltro' onChange={handleChange} value={aprobadorFiltro} variant="outlined" size="small" placeholder='Ingrese un texto para filtrar los Aprobadores'/>  
              </Grid>
              <Grid item xs={4}>
                {tipoVisita === 'gen'
                ? (<FormControlLabel disabled={(estado == Estados.NO_APTO || estado == Estados.NO_CARGADO) && tipo != Tipos.VISITA ? false : true} control={<Checkbox checked={autorizarPermiso} onChange={() => setAutorizarPermiso(!autorizarPermiso)} />} label="Autorizar Permiso" />)
                : (null)
                }
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={9}>
                <Paper variant="outlined" style={{padding: '0px 10px' }}>
                  <Stack
                    direction="column"
                    style={{
                      overflow: 'auto',
                      maxHeight: 250,
                    }}
                  >
                    {
                      autoridades.map((autoridad, index) => (
                        <FormControlLabel 
                          key={index} 
                          value={autoridad.Id_Supervisor}
                          label={autoridad.Name_Supervisor} 
                          control={<Checkbox 
                            size='small'
                            name='autoridad' 
                            checked={autoridadesSeleccionadas.includes(autoridad.Id_Supervisor.toString())}
                            value={autoridad.Id_Supervisor} 
                            onChange={handleChange}
                          />} 
                          disabled={(estado == Estados.NO_APTO || estado == Estados.NO_CARGADO) && tipo != Tipos.VISITA ? autorizarPermiso : true}
                        />
                      ))
                    }
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Nota al aprobador
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField fullWidth value={notaAprobador} onChange={handleChange} name='notaAprobador' variant="outlined" size="small" multiline minRows={3}/>
              </Grid>
            </Grid>
          </Paper>

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent= 'center'
            spacing={2}
            style={{ marginTop: 15}}
          >

        {/* MODAL MOTIVOS */}
        <Modal
        open={openModalMotivo}
        onClose={handleCloseModalMotivo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
         <Typography>Motivos:</Typography>
         <FormControl fullWidth >
          <RadioGroup>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              </TableRow>
            </TableHead>
            {
            motivos.map((motivo)=>
            <TableRow
              key={motivo.Id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell onClick={()=>{setIdMotivo(motivo.Id); setDescMotivo(motivo.Descripcion);}} style={{ padding: '5px 10px' }} component="th" scope="row">
            <FormControlLabel value={motivo.Id} control={<Radio />} label={motivo.Descripcion}/>
            </TableCell>
            </TableRow>
            )
            }
          </Table>
          </RadioGroup>
          </FormControl>
          <Typography>Nota Adicional:</Typography>
          <TextField fullWidth value={notaMotivo} onChange={handleChange} name='notaMotivo' variant="outlined" size="small" multiline minRows={3}/>
        
          <Button onClick={()=>{handleSubmitSetEntryAuthorizationAuthorized();}} variant="outlined" size="large" color="success" startIcon={<CheckIcon />}>Aceptar</Button>
        </Box>
        </Modal>


          {autorizarPermiso===false
          ?
            <Button type='submit' variant="outlined" size="large" color="success" startIcon={<CheckIcon />}>
              {
                estado == Estados.NO_APTO || estado == Estados.NO_CARGADO ? 
                  tipo != Tipos.VISITA ? 
                  'Gestionar permiso' 
                  : 
                  'Aprobar Visita'
                  : 
                  'Ingreso'}
            </Button>
          :
          <Button variant="outlined" size="large" color="success" onClick={()=> handleOpenModalMotivo() } startIcon={<CheckIcon />}>Gestionar permiso</Button>
          }
            <Button variant="outlined" size="large" color="error" onClick={() => !esModal ? props.handleHide() : props.closeModal()} startIcon={<CancelIcon />}>Cancelar</Button>
            <Button onClick={() => limpiarFormulario()} variant="outlined" size="large" color="info" startIcon={<AutorenewIcon />}>Limpiar</Button>
          </Stack>
        </form>

      </CardContent>
    </Card>
  );
}