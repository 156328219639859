/* eslint-disable react-hooks/exhaustive-deps */
import { fetchApi } from '../helpers/fetchApi';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardHeader, 
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import CircularProgress from '@mui/material/CircularProgress';
import ZoomOutSharpIcon from '@mui/icons-material/ZoomOutSharp';
import ZoomInSharpIcon from '@mui/icons-material/ZoomInSharp';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
}),
);

export default function PermisoDeIngresoPendiente(props) {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const { refresco } = useSelector((state: RootState) => state.refrescar);
  const navigate = useNavigate();
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const [cargando, setCargando] = useState(true);
  const lengthPrevia = useRef(0);
  const [permisos, setPermisos] = useState<any>([]);
  const [codigoPermisoString, setCodigoPermisoString] = useState<string>();
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const radioSelectIndex = useRef(-1);

  const urlGetPersonalPermisoPendiente = `https://scaapi.tecpetrol.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetPersonalPermisoPendiente?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}`;
  const getPersonalPermisoPendiente = async() => {
    return await fetchApi(`${urlGetPersonalPermisoPendiente}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };

  const [orderColumn, setOrderColumn]= useState("Fecha");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(permisos, columnName, columnOrder);

    setPermisos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async (diff?) => {
    const [payload, error] = await getPersonalPermisoPendiente();
    if(!error){
      lengthPrevia.current = payload.length;
      setCargando(true);
      setPermisos(payload);
      setUltimoRefresco(new Date().toTimeString());
      if(diff) {
        let rowNuevo = radioSelectIndex.current+diff;
        radioSelectIndex.current = rowNuevo;
      }
      setCargando(false);
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    const [payload, error] = await getPersonalPermisoPendiente();
    if(!error){
      if(payload.length !== lengthPrevia.current){
        let diff = payload.length - lengthPrevia.current;
        leer(diff);
      }
    }
  }

  const urlReenviarPermiso = `https://scaapi.tecpetrol.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/ReenviarPermiso?codigoPermiso=${codigoPermisoString}`

  const reenviarPermiso = async() => {
    return await fetchApi(`${urlReenviarPermiso}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
      // body: JSON.stringify({
      // codigoPermiso: codigoPermisoString
      // })
    });
  };

  const enviarReenviarPermiso = async () => {
    const [payload, error] = await reenviarPermiso();
    if(!error){
      //
    }
  }

  const handleRadio = (codigo, index) => {
    if(radioSelectIndex.current === index) {
      setCodigoPermisoString(null); 
      radioSelectIndex.current = -1;
    } else {
      setCodigoPermisoString(codigo); 
      radioSelectIndex.current = index;
    }
  }

  useEffect(() => {
    leer();
  }, [obra])

  useEffect(() => {
    if(props.isVisible){
      if(!cargando)
      verificarNuevosDatos();
    }else{
      leer();
    }
  }, [refresco])

  return (
    <Card sx={{ minWidth: 100 }} elevation={5} id="5">
      <CardHeader
        action={
          <ButtonGroup variant="text" aria-label="outlined primary button group">
            <Button size="small" disabled={cargando} color='info' variant='text' onClick={() => leer()}><CachedIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasFull()}><ZoomInSharpIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasHalf()}><ZoomOutSharpIcon/></Button>
            <Button size="small" onClick={() => navigate('/permisoIngresoPendiente')}><FullscreenIcon/></Button>
            <Button size='small' onClick={() => props.handleHide()}><CloseIcon/></Button>
          </ButtonGroup>
        }
        title="Permisos Ingreso Pendientes"
      >
      </CardHeader>
      <CardContent>
        <Button onClick={() => navigate('/ingresoPeatonal')}>Agregar</Button>
        <Button onClick={() => {enviarReenviarPermiso();}}>Reenviar permiso</Button>

        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 450 }}>
          
          <FormControl fullWidth>
          <RadioGroup>
          
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell width={0} padding={'none'}></TableCell>
              <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                    Fecha
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Personal')}>
                    <TableSortLabel active={orderColumn == 'Personal'} direction={orderColumn != 'Personal' || orderDirection === "asc"? "asc" : "desc"}>
                    Personal
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                    <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                    Contratista
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell>
                    Estado
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Supervisor')}>
                    <TableSortLabel active={orderColumn == 'Supervisor'} direction={orderColumn != 'Supervisor' || orderDirection === "asc"? "asc" : "desc"}>
                    Aprobador
                    </TableSortLabel>                    
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (permisos.map((permiso: any, index) => (
                  <TableRow
                    key={index}
                    id={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    selected={index === radioSelectIndex.current ? true : false}
                    onClick={() => {
                      setRowSelectIndex(index);
                    }}
                  >
                    <TableCell style={{ padding: 0, zIndex: 1 }} component="th" scope="row">
                      <FormControlLabel sx={{ padding: 0, margin: 0}} value={permiso.Code} control={
                      <Radio size={'small'} value={permiso.Code} checked={codigoPermisoString === permiso.Code} onClick={ () => {handleRadio(permiso.Code, index)} }/>
                      } label={""}/>
                    </TableCell>
                    <TableCell style={{ padding: '2px 10px' }} component="th" scope="row">
                      {permiso.Fecha}
                    </TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Personal}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Contratista}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">-</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{permiso.Supervisor}</TableCell>
                  </TableRow>
                )))
            }
            </TableBody>
          </Table>
          </RadioGroup>
          </FormControl>

          {cargando && <Box sx={{
          width: '100%',
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          margin: '1rem 0rem',
          }}>
          <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}