import { Alert, Grid, Slide, Snackbar, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DetalleDeVehiculoComponente from "../components/detalleDeVehiculo";
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import { fetchApi } from '../helpers/fetchApi';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { makeStyles, createStyles } from '@mui/styles';
import { valueToPercent } from "@mui/base";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '99%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '1rem',
  overflow: 'auto',
};

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
}),
);

const VehiculosEnCamino = () => {
  const lengthPrevia = useRef(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const [openDetalle, setOpenDetalle] = React.useState(false);
  const [patenteABuscar, setPatenteABuscar] = useState<string>();
  const [choferABuscar, setChoferABuscar] = useState<string>();
  const [vehiculos, setVehiculos] = useState<any>([]);
  const [cargando, setCargando] = useState(true);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();

  const handleOpen = () => {
    setOpenDetalle(true);
  } 
  
  const handleClose = () => {
    setOpenDetalle(false)
  };

  const [patenteBusqueda, setPatenteBusqueda] = useState<string>('');

  const handleChangePatenteBusqueda = e => {
    setPatenteBusqueda(e.target.value);
  }

  const urlGetVehiculoEstado = `https://scaapi.tecpetrol.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetVehiculoEstado?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}`;
  const getVehiculoEstado = async() => {
    return await fetchApi(`${urlGetVehiculoEstado}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };

  const [orderColumn, setOrderColumn]= useState("Vehiculo");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Cant_Personas" || columnName == "No_Aptos" || columnName == "Desconocidos" || columnName == "Cercania") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(vehiculos, columnName, columnOrder);

    setVehiculos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async () => {
    const [payload, error] = await getVehiculoEstado();
    if(!error){
      setCargando(true);
      lengthPrevia.current = payload.length
      setVehiculos(payload);
      setCargando(false);
      setUltimoRefresco(new Date().toTimeString());
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    const [payload, error] = await getVehiculoEstado();
    if(!error){
      if(payload.length !== lengthPrevia.current){
        leer();
      }
    }
  }

  useEffect(() => {
    leer();

    const interval = setInterval(() => {
      verificarNuevosDatos();
    }, 30000); // 30 seg
    return () => clearInterval(interval);
  }, [obra]);

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  

  return (
    <Dashboard title="Vehiculos En Camino" refresco={leer}>
      {/* <Snackbar sx={{ top: '5rem !important', zIndex: 9}} anchorOrigin={{vertical: 'top',horizontal: 'center'}} TransitionComponent={transitionSlide} open={open} autoHideDuration={15000} onClose={handleRefrescoClose}>
        <Alert icon={<CachedIcon fontSize="inherit" /> } onClose={handleRefrescoClose}  severity="info" sx={{ width: '100%' }} >
          <div onClick={() => {leer(); setOpen(false)}}>Nuevos datos, click para refrescar listado.</div>
        </Alert>
      </Snackbar> */}
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
      <FormControl fullWidth>
        <InputLabel>Obra</InputLabel>
        <Select
          label="Obra"
          value={obra.Id_Zone.toString()}
          fullWidth
          disabled
        >
          <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
        </Select>

        <TextField
          margin="normal"
          required
          fullWidth
          id="patente"
          label="Patente"
          name="patente"
          autoComplete="patente"
          value={patenteBusqueda}
          onChange={handleChangePatenteBusqueda}
          />
      </FormControl>
      
        <Button>Buscar</Button>

        <Modal
        open={openDetalle}
        onClose={handleClose}
        >
          <Box sx={style}>
         <DetalleDeVehiculoComponente patenteVehiculo={patenteABuscar} choferVehiculo={choferABuscar} />
         </Box>
        </Modal>

        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 900 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Vehiculo')}>
                <TableSortLabel active={orderColumn == 'Vehiculo'} direction={orderColumn != 'Vehiculo' || orderDirection === "asc"? "asc" : "desc"}>
                  Vehiculo
                </TableSortLabel>                    
              </TableCell>
              <TableCell onClick={(e)=> handleSortRequest('Chofer')}>
                <TableSortLabel active={orderColumn == 'Chofer'} direction={orderColumn != 'Chofer' || orderDirection === "asc"? "asc" : "desc"}>
                Chofer
                </TableSortLabel>                    
              </TableCell>
              <TableCell align="right" onClick={(e)=> handleSortRequest('Cant_Personas')}>
                <TableSortLabel active={orderColumn == 'Cant_Personas'} direction={orderColumn != 'Cant_Personas' || orderDirection === "asc"? "asc" : "desc"}>
                Ocupantes
                </TableSortLabel>                    
              </TableCell>
              <TableCell align="right" onClick={(e)=> handleSortRequest('No_Aptos')}>
                <TableSortLabel active={orderColumn == 'No_Aptos'} direction={orderColumn != 'No_Aptos' || orderDirection === "asc"? "asc" : "desc"}>
                No Aptos
                </TableSortLabel>                    
              </TableCell>
              <TableCell align="right" onClick={(e)=> handleSortRequest('Desconocidos')}>
                <TableSortLabel active={orderColumn == 'Desconocidos'} direction={orderColumn != 'Desconocidos' || orderDirection === "asc"? "asc" : "desc"}>
                No Cargado
                </TableSortLabel>                    
              </TableCell>
              <TableCell align="right" onClick={(e)=> handleSortRequest('Cercania')}>
                <TableSortLabel active={orderColumn == 'Cercania'} direction={orderColumn != 'Cercania' || orderDirection === "asc"? "asc" : "desc"}>
                Cercania (KM)
                </TableSortLabel>                    
              </TableCell>
              <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                Ult. Reporte
                </TableSortLabel>                    
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {!cargando && (vehiculos.filter((vehiculo: any)=> {
                  if(patenteBusqueda===''){
                    return vehiculo;
                  } else if (vehiculo.Vehiculo.toLowerCase().includes(patenteBusqueda.toLowerCase())){
                    return vehiculo;
                  }
                }).map((vehiculo: any, index) => (
                  <TableRow
                    key={index}
                    id={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={()=>{
                      setPatenteABuscar(vehiculo.Vehiculo);
                      setChoferABuscar(vehiculo.Chofer);
                      handleOpen();
                      setRowSelectIndex(index);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {vehiculo.Vehiculo}
                    </TableCell>
                    <TableCell align="right">{vehiculo.Chofer}</TableCell>
                    <TableCell align="right">{vehiculo.Cant_Personas}</TableCell>
                    <TableCell align="right">{vehiculo.No_Aptos}</TableCell>
                    <TableCell align="right">{vehiculo.Desconocidos}</TableCell>
                    <TableCell align="right">{vehiculo.Cercania}</TableCell>
                    <TableCell align="right">{vehiculo.Fecha}</TableCell>
                  </TableRow>
                  )))
                }
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default VehiculosEnCamino;