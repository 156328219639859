/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchApi } from '../helpers/fetchApi';
import DatePicker from '@mui/lab/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { es } from "date-fns/locale";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
}),
);


const AccesosPeatonales = () => {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const [cargando, setCargando] = useState(true);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const lengthPrevia = useRef(0);
  const [accesosPeatonales, setAccesosPeatonales] = useState<any>([]);
  const hoy = new Date();
  const [hoyString, setHoyString] = useState<string>();
  const [fechaMovimientoDesde, setFechaMovimientoDesde] = useState<Date | null>(hoy);
  const [fechaMovimientoHasta, setFechaMovimientoHasta] = useState<Date | null>(hoy);
  const [fechaMovimientoDesdeString, setFechaMovimientoDesdeString] = useState<string>(hoyString);
  const [fechaMovimientoHastaString, setFechaMovimientoHastaString] = useState<string>(hoyString);
  const [refrescarBusqueda, setRefrescarBusqueda] = useState<boolean>(true);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();

  let urlGetAccesosPeatonal= `https://scaapi.tecpetrol.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetAccesosPeatonal?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}&fechaMovimiento=${fechaMovimientoDesdeString}&fechaMovimientoHasta=${fechaMovimientoHastaString}`;
  const getMovimientosTotem = async() => {
    return await fetchApi(`${urlGetAccesosPeatonal}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };

  const [orderColumn, setOrderColumn]= useState("Fecha");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(accesosPeatonales, columnName, columnOrder);

    setAccesosPeatonales(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async () => {
    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();
    
    const [payload, error] = await getMovimientosTotem();
    if(!error){
      lengthPrevia.current = payload.length;
      setCargando(true);
      setAccesosPeatonales([]);
      setAccesosPeatonales(payload);
      setUltimoRefresco(new Date().toTimeString());
      setCargando(false);
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    const [payload, error] = await getMovimientosTotem();
    if(!error){
      if(payload.length !== lengthPrevia.current){
        leer();
      }
    }
  }
  
  useEffect(() => {
    fechaHoyString();
    leer();

    const interval = setInterval(() => {
      verificarNuevosDatos();
    }, 30000); // 30 seg
    return () => clearInterval(interval);

  }, [refrescarBusqueda, obra])

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  const fechaHoyString = () => {
    let diaHoy = hoy.getDate();
    let mesHoy = hoy.getMonth();
    let yearHoy = hoy.getFullYear();

    if (mesHoy<10 && diaHoy<10){
      setHoyString(`0${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy>=10 && diaHoy<10){
      setHoyString(`0${diaHoy}/${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy<10 && diaHoy>=10){
      setHoyString(`${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else{
      setHoyString(`${diaHoy}/${mesHoy}/${yearHoy}`);
    }
  }

  const fechaMovimientoDesdeAString = () => {
    let dia = fechaMovimientoDesde.getDate();
    let mes = fechaMovimientoDesde.getMonth() + 1;
    let year = fechaMovimientoDesde.getFullYear();

    if (mes<10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/0${mes}/${year}`);
    }
    else if (mes>=10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/${mes}/${year}`);
    }
    else if (mes<10 && dia>=10){
      setFechaMovimientoDesdeString(`${dia}/0${mes}/${year}`);
    }
    else{
      setFechaMovimientoDesdeString(`${dia}/${mes}/${year}`);
    }
  }

  const fechaMovimientoHastaAString = () => {
    let diaH = fechaMovimientoHasta.getDate();
    let mesH = fechaMovimientoHasta.getMonth() + 1;
    let yearH = fechaMovimientoHasta.getFullYear();

    if (mesH<10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/0${mesH}/${yearH}`);
    }
    else if (mesH>=10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/${mesH}/${yearH}`);
    }
    else if (mesH<10 && diaH>=10){
      setFechaMovimientoHastaString(`${diaH}/0${mesH}/${yearH}`);
    }
    else{
      setFechaMovimientoHastaString(`${diaH}/${mesH}/${yearH}`);
    }
  }

  //Pasa los metodos que pasan las fechas de Date a string para poder hacer la petición, 
  //luego llama al metodo que cambia el estado de refrescarBusqueda para que useEffect se active
  const fechasAString = ()=>{
    setCargando(true);
    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    botonBuscarRefrescarBusqueda();
  }

  const botonBuscarRefrescarBusqueda = () => {
    if(refrescarBusqueda===true){
      setRefrescarBusqueda(false);
    }
    else if (refrescarBusqueda===false){
      setRefrescarBusqueda(true);
    }
  }


  return (
    <Dashboard title="Accesos Peatonales" refresco={leer}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
        <Box sx={{
            '& > :not(style)': { m: 1 }, display: 'flex', width: '100%'
          }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack spacing={3} sx={{ width: '50ch' }}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoDesde}
                  onChange={
                    (newValue) => {
                    setFechaMovimientoDesde(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  
                />
              </Stack>
            </LocalizationProvider>
            {/* <Box sx={{ my: 2 }}>  </Box> */}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack spacing={3} sx={{ width: '50ch' }}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoHasta}
                  onChange={(newValue) => {
                    setFechaMovimientoHasta(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box sx={{  width: '100%' }}>
           <Button onClick={() => {fechasAString();}}>Buscar</Button>
          </Box>

        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 650 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                  <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                  Fecha
                  </TableSortLabel>                    
                </TableCell>
                <TableCell onClick={(e)=> handleSortRequest('Tipo_Movimiento_Desc')}>
                  <TableSortLabel active={orderColumn == 'Tipo_Movimiento_Desc'} direction={orderColumn != 'Tipo_Movimiento_Desc' || orderDirection === "asc"? "asc" : "desc"}>
                  Tipo
                  </TableSortLabel>                    
                </TableCell>
                <TableCell align="right" onClick={(e)=> handleSortRequest('Dni')}>
                  <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                  Dni
                  </TableSortLabel>                    
                </TableCell>
                <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                  <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                  Nombre
                  </TableSortLabel>                    
                </TableCell>
                <TableCell onClick={(e)=> handleSortRequest('Tipo')}>
                  <TableSortLabel active={orderColumn == 'Tipo'} direction={orderColumn != 'Tipo' || orderDirection === "asc"? "asc" : "desc"}>
                  Tipo
                  </TableSortLabel>                    
                </TableCell>
                <TableCell onClick={(e)=> handleSortRequest('Personal')}>
                  <TableSortLabel active={orderColumn == 'Personal'} direction={orderColumn != 'Personal' || orderDirection === "asc"? "asc" : "desc"}>
                  Personal
                  </TableSortLabel>                    
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando &&
                (accesosPeatonales.map((acceso, index) => (
                  <TableRow
                    key={index}
                    id={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // selected={index === rowSelectIndex ? true : false}
                    onClick={() => {
                      setRowSelectIndex(index);
                    }}
                  >
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                      {acceso.Fecha}
                    </TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{acceso.Tipo_Movimiento_Desc}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{acceso.Dni}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{acceso.Nombre}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{acceso.Tipo}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{acceso.Personal}</TableCell>
                  </TableRow>
                )))
              }
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default AccesosPeatonales;